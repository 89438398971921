import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Strony nie znaleziono – Synergius CRM" description="" />
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        background: "linear-gradient(to right, #56ccf2, #2f80ed)",
        color: "#f9f9f9"
      }}
    >
      <h1>404</h1>
      <p
        style={{
          color: "#f9f9f9"
        }}
      >
        Strona o tym adresie nie istnieje. Poznaj system SynergiusCRM. Przejdź na
        stronę{" "}
        <Link
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#2F40ED"
          }}
          to="/funkcje-crm/"
        >
          funkcje
        </Link>{" "}
        albo{" "}
        <Link
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#2F40ED"
          }}
          to="/"
        >
          stronę główną
        </Link>
        .
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
